// i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zh from './zh';
import en from './en';

Vue.use(VueI18n);
const defaultLocale = localStorage.getItem('lang') || 'zh';
console.log(defaultLocale)
const i18n = new VueI18n({
	locale: defaultLocale, // 默认语言
	messages: {
		'en': en,
		'zh': zh
	},
});

export default i18n;