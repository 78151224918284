<template>
	<div class="container">
		<div class="float-box" :class="isActive?'float-box-h':''">
			<div class="float-right" @mousemove="mousemove()" @mouseleave="mouseleave()">
				<div :class="index!=1?'float-right-hr':'float-right-hr2'">
				<img src="../assets/img/icon/kefu.png" alt="...">
				</div>
				<div class="float-right-move"  @mousemove="mousemovebox()" @mouseleave="mouseleavebox()" v-if="isMousemove">{{$t('home.home2')}}：0755 27189290</div>
			</div>
			<!-- <div class="float-box-hr">
				<hr />
			</div> -->
			<div class="float-right" @mousemove="telmousemove()" @mouseleave="telmouseleave()">
				<div :class="index!=2?'float-right-hr':'float-right-hr2'">
				<img src="../assets/img/icon/tel.png" alt="...">
				</div>
				<div class="float-right-move" style="width: 257px;left: -265px;" v-if="isTelMousemove">{{$t('home.home3')}}：+86 13660736734</div>
			</div>
			<!-- <div class="float-box-hr">
				<hr />
			</div> -->
			<div class="float-right" @click="goMessage()" @mousemove="mousemove3(2)" @mouseleave="mouseleave3(0)">
				<div :class="isActive && index!=3?'float-right-hr':'float-right-hr2'">
				<img src="../assets/img/icon/message.png" alt="...">
				</div>
			</div>
			<!-- <div class="float-box-hr" v-if="isActive">
				<hr />
			</div> -->
			<div class="float-right" v-if="isActive" @click="goTop()" @mousemove="mousemove3(3)" @mouseleave="mouseleave3(0)">
				<img src="../assets/img/icon/top.png" alt="...">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return {
				index:0,
				isActive:false,
				isMousemove:false,
				isTelMousemove:false,
			};
		},
		computed: {},
		watch: {},
		created() {
			
		},
		mounted() {
			// 监听页面滚动
			window.addEventListener('scroll', this.handleScroll)
		},
		methods: {
			mousemove3(index){
				this.index=index;
			},
			mouseleave3(index){
				this.index=0;
			},
			handleScroll() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				if (scrollTop >= 40) {
					this.isActive = true;
				} else {
					this.isActive = false;
				}
			},
			goTop(){
				window.scrollTo({
				  top: 0,
				  left: 100,
				  behavior: "smooth",//平滑滚动
				});
			},
			goMessage(){
				this.$router.push({ path: '/consult'})
			},
			mousemove(){
				this.index=0;
				this.isMousemove=true;
			},
			mouseleave(){
				this.isMousemove=false;
			},
			mousemovebox(){
				this.isMousemove=true;
			},
			mouseleavebox(){
				this.isMousemove=false;
			},
			telmousemove(){
				this.index=1;
				this.isTelMousemove=true;
			},
			telmouseleave(){
				this.index=0;
				this.isTelMousemove=false;
			}
		},
	}
</script>

<style scoped>
	.float-box {
	    position: fixed;
	    right: 24px;
	    top: 50%;
	    z-index: 999;
		
	   /* width: 54px;
	    height: 173px; */
	    background: #10B1A7;
	    border-radius: 4px 4px 4px 4px;
	}
	.float-box-h{
		/* height: 240px; */
	}
	.float-right{
		width: 54px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		cursor: pointer;
		border-radius: 4px 4px 4px 4px;
	}
	.float-right:hover{
		background: #19BFB5;
	}
	.float-right img{
		width: 28px;
		height: 28px;
	}
	.float-right-hr{
		width: 28px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid rgba(21, 194, 183, 1) !important;
	}
	.float-right-hr2{
		width: 28px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #10B1A7 !important;
	}
	.float-box-hr{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.float-box-hr hr{
		width: 30px;
		height: 1px;
		background: rgba(21, 194, 183, 1);
		border-radius: 0px 0px 0px 0px;
		border: revert !important;
		border-top: 1px solid rgba(21, 194, 183, 1) !important;
		opacity: 0.2;
		margin: 0 !important;
	}
	
	.float-right-move{
		position: absolute;
		left: -245px;
		
		
		width: 239px;
		height: 57px;
		background: #10B1A7;
		border-radius: 4px 4px 4px 4px;
		
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		line-height: 19px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		
		display: flex;
		align-items: center;
		padding-left: 24px;
	}
</style>