// 时间过滤器
export const filters = {
    formatDate: value => {
        if(value == undefined){
            return;
        }
        //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        value = value * 1000;
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM; //月补0
        return y + '年' + MM + '月'; //年月日
    },
    formatYear: value => {
        if(value == undefined){
            return;
        }
        //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        value = value * 1000;
        let date = new Date(value);
        let y = date.getFullYear();
        y = y.toString().substr(2,2);
        return y + '年上牌'; //年月日
    },
	//时间戳转年月日
	dateFormat:(value, partten = "YYYY-MM-DD") => {
		return moment(parseInt(value+'000')).format(partten);
	},
	/* // 将毫秒转为时分秒
	// 示例
	let timestamp = 2851630845;
	let timeString = transTimestampToTime(timestamp);
	console.log(timeString); // 输出：792时7分10秒
	let index = timeString.indexOf('时')
	console.log(timeString.slice(0, index)); // 输出：792 */
	
	transTimestampToTime:(value) => {
	    if (value === 0 || value < 1000) return `00:00:00:${value}`;
	    var timestamp = parseInt(value) / 1000; // 毫秒转秒
	    // 小时取余数
	    const remainder = timestamp % 3600
	    // 时、分、秒
		let hour=0;
		let minute=0;
		let second=0;
		let millisecond=0;
	    if (remainder === 0) { // 整除 小时
	        hour = parseInt(timestamp / 3600);
	    } else {
	        hour = parseInt(timestamp / 3600);
	        let remainderMinute = remainder % 60;
	        if (remainderMinute === 0) { // 整除 分钟
	            minute = parseInt(remainder / 60);
	        } else {
	            minute = parseInt(remainder / 60);
	            second = parseInt(remainderMinute);
	        }
	    }
		millisecond=parseInt(value) %1000;
	    let text = `${hour>=10?hour:'0'+hour}:${minute>=10?minute:'0'+minute}:${second>=10?second:'0'+second}:${millisecond>=10?millisecond:'0'+millisecond}`;
	    return text;
	},
	/* */
	regPwd(type){
		let reg ='';
		if(type==0){
			// reg= /^(\d{6,16})|((?! )(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9_]{6,16})$/;//数字字符6-16位
			reg = /^[^\u4e00-\u9fa5 ]{6,16}$/; //不含有中文和空格,6-16位
		}else if(type==1){
			reg= /^[A-Za-z0-9]+([-._][A-Za-z0-9]+)*@[A-Za-z0-9]+(-[A-Za-z0-9]+)*(\.[A-Za-z]{2,6}|[A-Za-z]{2,4}\.[A-Za-z]{2,3})$/;
		}else if(type==2){
			
		}else if(type==3){
			
		}
		return reg
	},
	//防抖(非立即执行)
	debounce_1(fn,wait){
	  var timerId = null;
	  return function(){
	  	var context = this
	    var args = arguments
	    clearTimeout(timerId);
	    timerId = setTimeout(() => {
	      fn.apply(context,args)
	    },wait)
	  }
	},
	//防抖(立即执行)
	debounce_2(fn,wait){
	  var timerId = null;
	  var flag = true;
	  return function(){
	  	var context = this
	    var args = arguments
	    clearTimeout(timerId);
	    if(flag){
	      fn.apply(context, args);
	      flag = false
	      }
	    timerId = setTimeout(() => { flag = true},wait)
	  }
	},
	//防抖(合并版)
	debounce_merge(fn,wait = 500,isImmediate = false){
	  var timerId = null;
	  var flag = true;
	  return function(){
	    var context = this
	    var args = arguments
	    clearTimeout(timerId )
	    if(isImmediate ){
	        if(flag){
	            fn.apply(context,args)
	            flag = false
	        }
	        timerId = setTimeout(function(){
	            flag = true
	        },wait)
	    }else{
	        timerId = setTimeout(function(){
	            fn.apply(context,args)
	        },wait)
	    }
	  }
	},
	//定义函数
	//节流(非立即执行)
	throttle_1(fn,wait){
	  var flag = true;
	  return function(){
	  	var context = this
	    var args = arguments
	    if(flag){
	      flag = false
	      setTimeout(() => {
	        fn.apply(context,args)
	        flag = true
	      },wait)
	    }
	  }
	},
	//节流(立即执行)
	throttle_2(fn,wait){
	  var flag = true;
	  return function(){
	  	var context = this
	    var args = arguments
	    if(flag) {
	      fn.apply(context,args);
	      flag = false;
	      setTimeout(() => {
	        flag = true
	      },wait)
	    }
	  }
	},
	//节流(合并)
	throttle_merge(fn,wait = 500,isImmediate = false){
	  var flag = true;
	  return function(){
	    if(flag == true){
	      var context = this
	      var args = arguments
	      flag = false
	      isImmediate && fn.apply(context,args)
	      setTimeout(() => {
	        !isImmediate && fn.apply(context,args)
	        flag = true
	      },wait)
	    }
	  }
	}
}