import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import GLightbox from 'glightbox';

import 'glightbox/dist/js/glightbox.min.js';
import "@popperjs/core";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'glightbox/dist/css/glightbox.min.css';
import "bootstrap" 

import 'font-awesome/css/font-awesome.min.css';
import './assets/css/reset.css'
import './assets/css/home.css'
import './assets/css/screen.css'
import 'boxicons/dist/boxicons.js'
import 'boxicons/css/boxicons.min.css'

import AOS from 'aos';
import 'aos/dist/aos.css';

import VueWaypoint from 'vue-waypoint'


// import 'swiper/swiper-bundle.min.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import enLocale  from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'

import i18n from '../i18n/i18n.js';
import { baseUrl } from "../common/baseUrl";
import moment from 'moment';

import AudioPlayer from './components/audio/index.js'
import VueQr from 'vue-qr'
import CryptoJS from 'crypto-js'

import { ImagePreview , Icon  } from '@femessage/vant';
import '@femessage/vant/lib/index.css';

import '../common/rem'
// import 'amfe-flexible';
locale.use(i18n.locale=='zh'?zhLocale:enLocale)
	
AOS.init(
  {
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
    mirror: false
  }
);

Vue.filter('dateFormat', (str, partten = "YYYY-MM-DD") => {
	return moment(parseInt(str+'000')).format(partten);
})

Vue.filter('fileSize', (bytes) => {
	if (bytes === 0) return '0 B';
	var k = 1024, // or 1024
	sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
	i = Math.floor(Math.log(bytes) / Math.log(k));
	if((bytes / Math.pow(k, i))>1000){
		return (bytes / Math.pow(k, i)).toPrecision(4) + ' ' + sizes[i];
	}else{
		return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
	}
	
})

Vue.filter('formatTime', (milliseconds) => {
	milliseconds=milliseconds/1000;
	let seconds = Math.ceil(milliseconds % 60);
	let minutes = Math.floor(milliseconds / 60);
	let hours = Math.floor(minutes / 60);
	//console.log(seconds)
	// seconds %= 60;
	// minutes %= 60;
	
	const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
	return formattedTime;
})

Vue.filter('formatTime2', (value) => {
	if (value === 0 || value < 1000) return `00:00:00`;
	var timestamp = parseInt(value) / 1000; // 毫秒转秒
	// 小时取余数
	const remainder = timestamp % 3600
	// 时、分、秒
	let hour=0;
	let minute=0;
	let second=0;
	if (remainder === 0) { // 整除 小时
	    hour = parseInt(timestamp / 3600);
	} else {
	    hour = parseInt(timestamp / 3600);
	    let remainderMinute = remainder % 60;
	    if (remainderMinute === 0) { // 整除 分钟
	        minute = parseInt(remainder / 60);
	    } else {
	        minute = parseInt(remainder / 60);
	        second = Math.ceil(remainderMinute);
	    }
	}
	let formattedTime = `${hour>=10?hour:'0'+hour}:${minute>=10?minute:'0'+minute}:${second>=10?second:'0'+second}`;
	return formattedTime;
})

Vue.filter('AESCode', (id) => {
	// let encryptedText = CryptoJS.MD5(id+Math.floor(100000 + Math.random() * 900000),'U2FsdGVkX1+Y/xpO87v3BZxJKjWS8tJF').toString();
	// return encryptedText;
	 return id;
})
Vue.filter('hasHttp', (url) => {
	//console.log(url)
	if(process.env.NODE_ENV === 'development'){
		url = 'http://192.168.2.114:8299'+url;
		//url = url;
	}
	// if(url.includes('http')){
	// 	url=url;
	// }else{
	// 	url=url;
	// }
	return url;
})

Vue.config.productionTip = false
Vue.prototype.$GLightbox = GLightbox;

// if(process.env.NODE_ENV === 'development'){
// 	Vue.prototype.$baseUrl = 'http://192.168.2.114:8299';
// }else{
// 	Vue.prototype.$baseUrl = window.location.host;// || 'https://c.vormor.cn'
// }
//Vue.prototype.$baseUrl = 'http://192.168.2.114:8299';

Vue.prototype.$baseImg = 'http://bbzn-sz.oss-cn-shenzhen.aliyuncs.com';


Vue.directive('focus', {
  inserted: function (el) {
    // el.focus()
    el.querySelector('input').focus()
  }
})

Vue.use(VueWaypoint);
Vue.use(AudioPlayer);
Vue.use(VueQr);
Vue.use(ElementUI);
Vue.use(ImagePreview);
Vue.use(Icon);
new Vue({
  router,
  store,
   i18n,
  render: h => h(App)
}).$mount('#app')
