<template>
	<div>
		<section id="hero" class="d-flex align-items-center justify-content-center">
			<div id="carouselExampleIndicators" class="carousel slide carousel-fade carousel-dark"
				data-bs-ride="carousel">
				<div class="carousel-inner">
					<div class="carousel-item active">
						<img src="../assets/img/icon/banner3.png" class="d-block w-100" alt="...">
						<div
							class="hero-desc carousel-caption carouselExampleIndicators-center carouselExampleIndicators-left">
							<h5 class="hero-desc-tilte">{{$t('aiMode.aiMode1')}}</h5>
							<p class="hero-desc-text" style="width: 55%;">{{$t('aiMode.aiMode2')}}</p>
							<div class="hero-vedio d-flex">
								<a href="/consult" class="btn-get-started-about scrollto">{{$t('aiMode.aiMode3')}}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- 核心能力 -->
		<section id="core-services" class="core-services core-services-bg-none">
			<div class="container">
				<div class="section-title">
				  <h3>{{$t('aiMode.aiMode4')}}</h3>
				  <p>{{$t('aiMode.aiMode5')}}</p>
				</div>
				<!--  justify-content-flex-start -->
				<div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4">
					<div class="col-6">
						<div class="core-box-1">
							<img src="../assets/img/icon/f1.png" style="width: 64px;height: 64px;" alt="">
							<p class="p1">{{$t('aiMode.aiMode6')}}</p>
							<p class="p2">{{$t('aiMode.aiMode7')}}</p>
						</div>
					</div>
					<div class="col-6">
						<div class="core-box-1">
							<img src="../assets/img/icon/f3.png" style="width: 64px;height: 64px;" alt="">
							<p class="p1">{{$t('aiMode.aiMode8')}}</p>
							<p class="p2">{{$t('aiMode.aiMode9')}}</p>
						</div>
					</div>
					<div class="col-6">
						<div class="core-box-1">
							<img src="../assets/img/icon/f4.png" style="width: 64px;height: 64px;" alt="">
							<p class="p1">{{$t('aiMode.aiMode10')}}</p>
							<p class="p2">{{$t('aiMode.aiMode11')}}</p>
						</div>
					</div>
					<div class="col-6">
						<div class="core-box-1">
							<img src="../assets/img/icon/f22.png" style="width: 64px;height: 64px;" alt="">
							<p class="p1">{{$t('aiMode.aiMode12')}}</p>
							<p class="p2">{{$t('aiMode.aiMode13')}}</p>
						</div>
					</div>
					<div class="col-6">
						<div class="core-box-1">
							<img src="../assets/img/icon/f2.png" style="width: 64px;height: 64px;" alt="">
							<p class="p1">{{$t('aiMode.aiMode14')}}</p>
							<p class="p2">{{$t('aiMode.aiMode15')}}</p>
						</div>
					</div>
					<div class="col-6">
						<div class="core-box-1">
							<img src="../assets/img/icon/f5.png" style="width: 64px;height: 64px;" alt="">
							<p class="p1">{{$t('aiMode.aiMode16')}}</p>
							<p class="p2">{{$t('aiMode.aiMode17')}}</p>
						</div>
					</div>
					<div class="col-6">
						<div class="core-box-1">
							<img src="../assets/img/icon/f6.png" style="width: 64px;height: 64px;" alt="">
							<p class="p1">{{$t('aiMode.aiMode18')}}</p>
							<p class="p2">{{$t('aiMode.aiMode19')}}</p>
						</div>
					</div>
					<div class="col-6">
						<div class="core-box-1">
							<img src="../assets/img/icon/f7.png" style="width: 64px;height: 64px;" alt="">
							<p class="p1">{{$t('aiMode.aiMode20')}}</p>
							<p class="p2">{{$t('aiMode.aiMode21')}}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- 左右展示 -->
		<section id="about-services" class="about-services">
			<div class="container" style="margin-top: 89px;">
				<div class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
					<div class="col-12">
						<div class="core-box-view-2">
							<div class="core-box-icon">
								<img src="../assets/img/icon/t2.png" style="width: 64px;height: 64px;" alt="">
							</div>
							<div class="subTilte">{{$t('aiMode.aiMode23')}}</div>
							<div  class="subContent">{{$t('aiMode.aiMode24')}}</div>
							<div class="lable-line"></div>
						</div>
					</div>
					<div class="col-12">
						<div  class="core-box-view-1">
							<img src="../assets/img/icon/t1.png"/>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="about-services" class="about-services" style="background: #F8F8F8;">
			<div class="container" style="margin-top: 89px;">
				<div class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
					<div class="col-12">
						<div  class="core-box-view-1" style="justify-content: flex-start;">
							<img src="../assets/img/icon/t3.png"/>
						</div>
					</div>
					<div class="col-12">
						<div class="core-box-view-2" style="align-items: flex-end;">
							<div class="core-box-icon">
								<img src="../assets/img/icon/t4.png" style="width: 64px;height: 64px;" alt="">
							</div>
							<div class="subTilte">{{$t('aiMode.aiMode25')}}</div>
							<div  class="subContent">{{$t('aiMode.aiMode26')}}</div>
							<div class="lable-line"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="about-services" class="about-services" style="padding-bottom: 10px;">
			<div class="container" style="margin-top: 89px;">
				<div class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
					<div class="col-12">
						<div class="core-box-view-2">
							<div class="core-box-icon">
								<img src="../assets/img/icon/t5.png" style="width: 64px;height: 64px;" alt="">
							</div>
							<div class="subTilte">{{$t('aiMode.aiMode27')}}</div>
							<div  class="subContent">{{$t('aiMode.aiMode28')}}</div>
							<div class="lable-line"></div>
						</div>
					</div>
					<div class="col-12">
						<div  class="core-box-view-1">
							<img src="../assets/img/icon/t6.png"/>
						</div>
					</div>
				</div>
				<div class="section-title" style="margin-bottom: 30px;padding-top: 147px;">
				  <h3>{{$t('aiMode.aiMode22')}}</h3>
				</div>
			</div>
		</section>
		
		<section id="featured-services" class="featured-services">
		  <div class="container">
		    <div class="row">
		      <div class="col-lg-3 col-md-6 col-6  d-flex mb-5 mb-lg-0">
		        <div class="icon-box-view">
		         <h4 class="title" v-show="(language!='zh')?true:false">
						<p class="title-c2">{{$t('aiMode.aiMode29')}}，</p>
						<p class="title-c3">{{$t('aiMode.aiMode30')}}</p>
				  </h4>
				  <h4 class="title" v-show="(language=='zh')?true:false">{{$t('aiMode.aiMode29')}}，<span class="title-c">{{$t('aiMode.aiMode30')}}</span></h4>
		          <p class="description">{{$t('aiMode.aiMode31')}}</p>
		        </div>
		      </div>
		      <div class="col-lg-3 col-md-6 col-6 d-flex mb-5 mb-lg-0">
		        <div class="icon-box-view">
					<h4 class="title" v-show="(language!='zh')?true:false">
						<p class="title-c2">{{$t('aiMode.aiMode32')}}，</p>
						<p class="title-c3">{{$t('aiMode.aiMode33')}}</p>
					</h4>
		          <h4 class="title" v-show="(language=='zh')?true:false">{{$t('aiMode.aiMode32')}}，<span class="title-c">{{$t('aiMode.aiMode33')}}</span></h4>
		          <p class="description">{{$t('aiMode.aiMode34')}}</p>
		        </div>
		      </div>
		
		      <div class="col-lg-3 col-md-6 col-6 d-flex mb-5 mb-lg-0">
		        <div class="icon-box-view">
					<h4 class="title" v-show="(language!='zh')?true:false">
						<p class="title-c2">{{$t('aiMode.aiMode35')}}，</p>
						<p class="title-c3">{{$t('aiMode.aiMode36')}}</p>
					</h4>
		          <h4 class="title" v-show="(language=='zh')?true:false">{{$t('aiMode.aiMode35')}}，<span class="title-c">{{$t('aiMode.aiMode36')}}</span></h4>
		          <p class="description">{{$t('aiMode.aiMode37')}}</p>
		        </div>
		      </div>
		
		      <div class="col-lg-3 col-md-6 col-6 d-flex mb-5 mb-lg-0">
		        <div class="icon-box-view">
					<h4 class="title" v-show="(language!='zh')?true:false">
						<p class="title-c2">{{$t('aiMode.aiMode38')}}，</p>
						<p class="title-c3">{{$t('aiMode.aiMode39')}}</p>
					</h4>
		          <h4 class="title" v-show="(language=='zh')?true:false">{{$t('aiMode.aiMode38')}}，<span class="title-c">{{$t('aiMode.aiMode39')}}</span></h4>
		          <p class="description">{{$t('aiMode.aiMode40')}}</p>
		        </div>
		      </div>
		    </div>
		  </div>
		</section>
	</div>
</template>

<script>
	export default {
		name: 'MediumDetails',
		components: {},
		props: {},
		data() {
			return {
				language: 'zh',
			};
		},
		computed: {},
		watch: {},
		methods: {

		},
		created() {

		},
		mounted() {
			let lang = localStorage.getItem('lang') || 'zh';
			let langlocale = (lang === 'en' ? 'en' : 'zh');
			this.language = langlocale;
		},
	}
</script>

<style lang="scss" scoped>
	.core-services{
		padding: 80px 0 0;
		background-image: url('../assets/img/header/mian1.png');
		background-size: cover;
	}
	.carousel-item{
		min-height: 240px;
		img{
			min-height: 240px;
		}
	}
	.core-services-bg-none{
		background-image:none;
	}
	.new-services-none{
		padding-top: 30px;
	}
	.section-title{
		margin-bottom: 60px;
	}
	.btn-get-started-about {
		width: 176px;
		height: 54px;
		background: #10B1A7;
		border-radius: 4px 4px 4px 4px;

		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 23px;
		text-align: center;
		font-style: normal;
		text-transform: none;

		display: flex;
		align-items: center;
		justify-content: center;

		margin-top: 48px;
	}

	.Medium-Page-services {
		padding-top: 60px;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
	}

	.Medium-services {
		padding-top: 80px;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;

		.core-box-view-img-1 {
			width: 432px;
			height: 270px;
		}

		.core-box-view-img {
			width: 432px;
			height: 270px;
			overflow: hidden;

			img {
				width: 432px;
				height: 270px;
			}

			img:hover {
				width: 432px;
				height: 270px;
				transform: scale(1.2);
			}
		}

		.core-box-view {
			flex: 1;
			.lable-line {
				width: 72px;
				height: 8px;
				background: linear-gradient(90deg, #10B1A7 0%, rgba(93, 228, 219, 0) 100%);
				border-radius: 0px 0px 0px 0px;
				margin: 20px 0 28px;
			}
		}

		.core-box-1 {
			border-radius: 0px 0px 0px 0px;

			.lable {
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 500;
				font-size: 20px;
				color: #333333;
				line-height: 36px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				cursor: pointer;
			}

			.subTilte {
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
				line-height: 25px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				cursor: pointer;
				margin-top: 20px;
			}

			.subContent {
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 14px;
				color: #999999;
				line-height: 25px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				margin-top: 36px;

				display: flex;
				align-items: center;

				img {
					width: 16px;
					height: 16px;
					margin-right: 7px;
				}
			}

			.subContent2 {
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 14px;
				color: #999999;
				line-height: 25px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				margin-top: 18px;
			}
		}
	}
	
	.Medium-Page-box-view{
		padding: 0 0 60px;
		display: flex;
		justify-content: center;
	}
	
	@media (max-width: 768px) {
		.Medium-services {
			padding-top: 0px;
		}
		.core-box-view-img-1 {
			width: 100%;
			height: auto;
			margin-top: 0;
			img {
				margin-top: 0;
				width: 100% !important;
				height: 270px;
				margin-left: 0;
				position: static;
			}
		}
	
		.core-box-1 {
			height: auto !important;
		}
	
		.core-box-view-img {
			margin-top: 60px;
			width: 100% !important;
			height: 270px;
			margin-left: 0;
			position: static;
			img {
				width: 100%;
				height: 270px;
			}
		}
	}
	.core-services .col-6{
		margin-bottom: 80px;
	}
	.core-services .col-6 .core-box-1 .p1{ 
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		color: #333333;
		line-height: 23px;
		text-align: center;
		font-style: normal;
		text-transform: none;
		margin-top: 18px;
	}
	.core-services .col-6 .core-box-1 .p2{
		// max-width: 195px;
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 16px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		margin-top: 12px;
		width: 75%;
	}
	
	.about-services{
		padding: 20px 0 142px;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
		
		.core-box-view-1{
			display: flex;
			justify-content: flex-end;
			
			img{
				// width: 488px;
				// height: 483.78px;
				width: 32rem;
				height: 30.2rem;
			}
			@media (max-width: 768px) {
			  img{
			  	margin-top: 60px;
			  	width: 100%;
			  	height: 260px;
				margin-left: 0;
				position: static;
			  }
			}
			
		}
		.core-box-view-2{
			display: flex;
			flex-direction: column;
			.lable-line{
				width: 72px;
				height: 8px;
				background: linear-gradient( 90deg, #10B1A7 0%, rgba(93,228,219,0) 100%);
				border-radius: 0px 0px 0px 0px;
				margin: 60px 0 0;
			}
			.core-box-icon{
				// margin-top: 89px;
			}
			.subTilte{
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 500;
				font-size: 2.5rem;
				color: #333333;
				line-height: 47px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				margin-top: 48px;
			}
			.subContent{
				width: 50%;
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 14px;
				color: #999999;
				line-height: 25px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				margin-top: 32px;
			}
			.subContent2{
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 14px;
				color: #999999;
				line-height: 25px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				margin-top: 18px;
			}
		}
	}
	
	.featured-services{
		.icon-box-view{
			width: 282px;
			min-height: 310px;
			background: #FFFFFF;
			box-shadow: 0px 4px 32px 0px rgba(0,0,0,0.1);
			border-radius: 0px 0px 0px 0px;
			
			padding: 42px 26px;
			.title{
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 500;
				font-size: 24px;
				color: #333333;
				line-height: 28px;
				text-align: left;
				font-style: normal;
				text-transform: none;
			}
			.description{
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 14px;
				color: #999999;
				line-height: 32px;
				text-align: left;
				font-style: normal;
				text-transform: none;
			}
			.title-c{
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 500;
				font-size: 24px;
				color: #333333;
				line-height: 28px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				color: #10B1A7;
			}
			.title-c2{
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 500;
				font-size: 20px;
				color: #333333;
				line-height: 28px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				color: #333333;
			}
			.title-c3{
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 500;
				font-size: 20px;
				color: #333333;
				line-height: 28px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				color: #10B1A7;
			}
		}
	}
	
	
	@media (max-width: 991px) {
		#carouselExampleIndicators .hero-desc{
			left: 30px;
			right: 30px;
		}
		#carouselExampleIndicators .hero-desc h5{
			font-size: 24px;
			margin-top: 40px;
		}
		.hero-desc-text{
			width: 100% !important;
			max-width: 100% !important;
			margin-top: 0 !important;
			line-height: 18px !important;
			font-size: 15px !important;
				
			overflow:hidden;
			text-overflow:ellipsis;
			display:-webkit-box; 
			-webkit-box-orient:vertical;
			-webkit-line-clamp:2 !important;
		}
		.about-services{
			.core-box-icon{
				margin-top:30px !important;
			}
			.subTilte{
				font-size: 26px !important;
			}
			.subContent{
				width: 100% !important;
			}
		}
		.featured-services{
			.icon-box-view{
				 min-height: auto !important;
				padding: 15px 10px;
				.title{
					font-size: 16px !important;
					.title-c{
						font-size: 16px !important;
					}
				}
			}
		}
		.btn-get-started-about{
			width: 100px !important;
			height: 35px !important;
			font-size: 15px !important;
		}
	}
</style>